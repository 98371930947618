.CrewMemberList .card-header {
  font-weight: bold;
}

.CrewMemberList .search-option-value {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.CrewMembers .NewSearch {
  width: 100%;
  text-align: right;
}

.CrewMembers table {
  margin-top: 1rem;
}

.CrewMembers .UserCards {
  margin-top: 1rem;
}

.CrewMembers .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.CrewMembers .top-nav {
  margin-left: -1.2rem;
  margin-right: -1.2rem;
}

.CrewMembers .mb-05rem {
  margin-bottom: 0.5rem;
}

.CrewMembers .mt-1rem {
  margin-top: 1rem;
}

.CrewMembers .ml-025rem {
  margin-left: 0.25rem;
}

.CrewMembers .ml-05rem {
  margin-left: 0.5rem;
}

.CrewMemberList .ml-1rem {
  margin-left: 1rem;
}

.CrewMemberList .ml-15rem {
  margin-left: 1.5rem;
}

.CrewMembers .mr-05rem {
  margin-right: 0.5rem;
}

.User .UserCard {
  margin-bottom: 1rem;
}

.User .nav-tabs {
  margin-top: 0.25rem;
}

.User .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.User .checkBoxContainer {
  margin-bottom: 1rem;
}

.User .ml-05rem {
  margin-left: 0.5rem;
}

.User .ml-025rem {
  margin-left: 0.25rem;
}

.User .Org {
  margin-bottom: 1rem;
}

.User .mt-1rem {
  margin-top: 1rem;
}

.User .access {
  margin-top: 1rem;
}

.User .card-header-success {
  color: #ffffff;
  background-color: rgb(40, 167, 68);
}

.User .card-header-danger {
  color: #ffffff;
  background-color: rgb(220, 53, 70);
}

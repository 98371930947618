.ImportCrewMemberResourcesComp .mb-1rem {
  margin-bottom: 1rem;
}

.ImportCrewMemberResourcesComp .mt-1rem {
  margin-top: 1rem;
}

.ImportCrewMemberResourcesComp .collapse-button {
  float: right;
  padding: 0.25px 15px;
}

.ImportCrewMemberResourcesComp .top-nav {
  margin-left: -1.2rem;
  margin-right: -1.2rem;
}

.ImportCrewMemberResourcesComp .nav-tabs {
  margin-top: 0.25rem;
}

.ImportCrewMemberResourcesComp .nav-link {
  font-weight: bold;
  cursor: pointer;
}

.ImportCrewMemberResourcesComp .mb-0rem {
  margin-bottom: 0rem;
}

.DeleteCrewMemberDataComp .card-header {
  font-weight: bold;
}

.DeleteCrewMemberDataComp .search-option-value {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.DeleteCrewMemberDataComp .ml-1rem {
  margin-left: 1rem;
}

.DeleteCrewMemberDataComp .ml-15rem {
  margin-left: 1.5rem;
}

.DeleteCrewMemberDataComp .mb-1rem {
  margin-bottom: 1rem;
}

.DeleteCrewMemberDataComp .mt-1rem {
  margin-top: 1rem;
}

.DeleteCrewMemberDataComp .mb-025rem {
  margin-bottom: 0.25rem;
}

.DeleteCrewMemberDataComp .overflow-500 {
  max-height: 300px;
  overflow: scroll;
  font-size: small;
}

.DeleteCrewMemberDataComp .custom-file {
  margin-bottom: 25px;
}

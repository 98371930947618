.SeedCollection .card-header {
  font-weight: bold;
}

.SeedCollection .card-header-primary {
  color: #ffffff;
  background-color: rgb(0, 123, 255);
}

.SeedCollection .card-header-success {
  color: #ffffff;
  background-color: rgb(40, 167, 68);
}

.SeedCollection .card-header-warning {
  color: #ffffff;
  background-color: rgb(255, 193, 7);
}

.SeedCollection .card-header-danger {
  color: #ffffff;
  background-color: rgb(220, 53, 70);
}

.SeedCollection .overflow-500 {
  max-height: 300px;
  overflow: scroll;
  font-size: small;
}

.SeedCollection .btn-float-right {
  float: right;
}
